import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";
import ContactForm from "../components/contact-form";

import parse from 'html-react-parser';

const ContactUs = () => {
  const data  = useStaticQuery(graphql`
    query ContactUsPage {
      allWpPage(filter: {uri: {eq: "/contact-us/"}}) {
        nodes {
          title
          contactUsPage {
            contactUsDescription
            googleMapsLink
          }
        }
      }
    }
  `);

  let contactUsData = data.allWpPage.nodes[0];
  let contactUsPageData = contactUsData.contactUsPage;
  let pageTitle = contactUsData.title;
  let contactUsDescription = contactUsPageData.contactUsDescription;
  let googleMapsLink = contactUsPageData.googleMapsLink;

  return (
    <Layout>
      <Seo title="Contact Us"/>
      <section className="container contact-us-page page-default">
        <div className="text-center text-lg-start">
          <h1 className="display-2 pt-5">{  pageTitle }</h1>
        </div>
        <div className="contact-us-description mt-5">
          { parse(contactUsDescription) }
        </div>
        <div className="row mt-5">
          <div className="col-12 col-lg-6">
            <ContactForm/>
          </div>
          <div className="col-12 col-lg-6 mt-5 mt-lg-0">
            <iframe src={googleMapsLink} title="google-maps"></iframe>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default ContactUs;